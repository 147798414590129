import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {BackwardIcon, ForwardIcon, HomeIcon} from "@heroicons/react/24/solid";
import Modal from 'react-modal';
import { useNavigate, useLocation } from "react-router-dom";
import Breadcrumb from "../components/breadcrumb";
import BackendUrl from "../DBConfig";
import {ArrowDownTrayIcon, ArrowPathIcon, DocumentTextIcon, MapPinIcon} from "@heroicons/react/24/outline";
import Pdf from "../components/PDF-Popup";

const Applicants = (key, value) => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Get values from URL parameters
    const keywordInitial = queryParams.get('keyword');
    const nationalityInitial = queryParams.get('nationality');
    const ageRangeInitial = queryParams.get('age');

    // Set initial state based on URL parameters or empty string if not present
    const [keyword, setKeyword] = useState(keywordInitial || '');
    const [nationality, setNationality] = useState(nationalityInitial || '');
    const [ageRange, setAgeRange] = useState(ageRangeInitial || '');


    const navigate = useNavigate();
    const [Client, setClient] = useState([]);
    const [applicantData, setApplicantData] = useState([]);
    const [applicantCategories, setApplicantCategories] = useState([]);
    const [applicantCount, setApplicantCount] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [totalApplicants, setTotalApplicants] = useState(0);
    const [categoryFilter, setCategoryFilter] = useState('');
    const [ageRangeFilter, setAgeRangeFilter] = useState('');
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        if (!keywordInitial && !nationalityInitial && !ageRangeInitial) {
            setKeyword('');
            setNationality('');
            setAgeRange('');
        } else{
            handleSearch();
        }
    }, [keywordInitial, nationalityInitial, ageRangeInitial]);




    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {

                const storedClient = localStorage.getItem("client");
                setClient(JSON.parse(storedClient));

                // Check if the user is authenticated
                if (!Client || !Client.username) {

                    // Recheck if the user is authenticated after the delay
                    const reStoredClient = localStorage.getItem("client");
                    const parsedClient = JSON.parse(reStoredClient);

                    if (!parsedClient || !parsedClient.username) {
                        // Redirect to the login page if not authenticated
                        navigate('/login');
                        return;
                    }

                    // Set the retrieved client data
                    setClient(parsedClient);
                }

                // Clear existing data when fetching new data
                setApplicantData([]);
                localStorage.removeItem('applicant');

                try {
                    // Adjust the endpoint URL to include the number of records per page and the correct offset for pagination
                    const response = await axios.get(`${BackendUrl}/applicant?page=${currentPage}&pageSize=${recordsPerPage}`);
                    const newData = response.data;

                    setApplicantData(newData);

                    const countOfReceivedData = newData.length;
                    setApplicantCount(countOfReceivedData);

                    try {
                        const categoriesResponse = await axios.get(`${BackendUrl}/applicant/categories/`);
                        const allCategories = categoriesResponse.data;

                        setApplicantCategories(allCategories);
                    } catch (error) {
                        console.error('Error fetching applicant categories:', error);
                    }
                } catch (error) {
                    setLoading(true);
                    console.error('Error fetching data:', error);
                    // Retry after 5 seconds
                    setTimeout(() => {
                        fetchData();
                    }, 5000);
                }
            } catch (error) {
                navigate("/login");
                console.error("Error fetching data:", error);
                // Retry after 5 seconds
                setTimeout(() => {
                    fetchData();
                }, 5000);
            }finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, recordsPerPage]);

    useEffect(() => {
        const fetchTotalApplicants = async () => {
            try {
                const response = await axios.get(`${BackendUrl}/applicant/total-count`);
                console.log(response);
                const totalCount = response.data.totalCount // Assuming the response contains totalCount
                setTotalApplicants(totalCount);
            } catch (error) {
                console.error('Error fetching total count of applicants:', error);
            }
        };

        fetchTotalApplicants();
    }, []);


    useEffect(() => {

        const handleFilter = async () => {
            setLoading(true);
            try {
                let url = `${BackendUrl}/applicant/search?`;

                // Append category filter if provided
                if (categoryFilter) {
                    const encodedCategory = encodeURIComponent(categoryFilter);
                    url += `keyword=${encodedCategory}`;
                }

                // Check if both category and age range filters are provided
                if (categoryFilter && ageRangeFilter) {
                    url += '&';
                }

                // Append age range filter if provided
                if (ageRangeFilter) {
                    url += `ageRange=${ageRangeFilter}`;
                }

                // Append pagination parameters
                url += `&page=${currentPage}&pageSize=${recordsPerPage}`;

                console.log(url);
                const response = await axios.get(url);

                const filteredApplicants = response.data;
                setApplicantData(filteredApplicants);

                const countOfReceivedData = filteredApplicants.length;
                setApplicantCount(countOfReceivedData);

                setLoading(false);
            } catch (error) {
                console.error('Error filtering applicants:', error);
                toast.error('Filtering failed');
            }
        };
        handleFilter();
    },[categoryFilter, ageRangeFilter]);

    // Update category filter state when a category is selected
    const handleCategoryChange = (category) => {
        setCategoryFilter(category);
    };

// Update age range filter state when an age range is selected
    const handleAgeRangeChange = (range) => {
        setAgeRangeFilter(range);
    };




    const handleSearch = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BackendUrl}/applicant/search?keyword=${keyword}&nationality=${nationality}&ageRange=${ageRange}&page=${currentPage}&pageSize=${recordsPerPage}`);
            const filteredApplicants = response.data;
            setApplicantData(filteredApplicants);

            const countOfReceivedData = filteredApplicants.length;
            setApplicantCount(countOfReceivedData);

            setLoading(false);
            toast.success("Search Successful")

        } catch (error) {
            console.error('Error searching applicants:', error);
            toast.error('Search failed');
        }
    };


    const handleReload = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BackendUrl}/applicant?page=${currentPage}&pageSize=${recordsPerPage}`);
            const newData = response.data;

            setApplicantData(newData);

            const countOfReceivedData = newData.length;
            setApplicantCount(countOfReceivedData);

            setKeyword('');
            setNationality('');
            setAgeRange('');

            await fetchTotalApplicants();
            setLoading(false);

            toast.success('Reloaded successfully');
        } catch (error) {
            console.error('Error reloading applicant data:', error);
            toast.error('Reloading failed');
        }
    };

    const fetchTotalApplicants = async () => {
        try {
            const response = await axios.get(`${BackendUrl}/applicant/total-count`);
            console.log(response);
            const totalCount = response.data.totalCount // Assuming the response contains totalCount
            setTotalApplicants(totalCount);
        } catch (error) {
            console.error('Error fetching total count of applicants:', error);
        }
    };

    const moreDetails = (applicantId) => {
        navigate(`/applicant/info/${applicantId}`);
    };

    const handlePDFDownload = (applicantId) => {
        navigate(`/applicant/cv/${applicantId}`);
    };


    const handleRecordsPerPageChange = (e) => {
        setRecordsPerPage(parseInt(e.target.value)); // Step 3: Handle change event of the dropdown
        setCurrentPage(1); // Reset current page to 1 when records per page changes
    };

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
        if (bottom && !loading) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const totalPages = Math.ceil(totalApplicants / recordsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }

        scrollToTop();
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
        scrollToTop();
    };


    const handleScrollToTop = () => {
        if (window.scrollY > 100) {
            setIsButtonVisible(true);
        } else {
            setIsButtonVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollToTop);

        return () => {
            window.removeEventListener('scroll', handleScrollToTop);
        };
    }, []);

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: "/" },
        { label: "Applicants", url: "/applicant" },
    ];



    return (
        <>
            <ToastContainer
                position="bottom-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="flex flex-col justify-start items-center">
                <div className="w-9/12 overscroll-y-auto text-neutral-200">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative">
                                {!Client || Object.keys(Client).length === 0 ? (
                                    <div className="absolute right-0">Welcome, Loading...</div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{Client.username || ''} </strong> | {formattedTime}
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>

                    <section className="bg-neutral-200 px-8 pb-4 w-full h-auto  sm:top-[450px] max-w-7xl mt-3">
                        <div className="flex flex-col sm:flex-row px-2">
                            <input
                                type="text"
                                name="keyword"
                                id="keyword"
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                className="block mt-3 w-full sm:w-1/3 rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-stone-100"
                                placeholder="Find the job title, company, keyword"
                            />
                            <input
                                type="text"
                                name="nationality"
                                id="nationality"
                                value={nationality}
                                onChange={(e) => setNationality(e.target.value)}
                                className="mt-3 sm:mt-0 ml-0 sm:ml-3 block w-full sm:w-1/4 rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-stone-100"
                                placeholder="Select Nationality"
                            />
                            <input
                                type="text"
                                name="ageRange"
                                id="ageRange"
                                value={ageRange}
                                onChange={(e) => setAgeRange(e.target.value)}
                                className="mt-3 sm:mt-0 ml-0 sm:ml-3 block w-full sm:w-1/4 rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-stone-100"
                                placeholder="Select Age Range"
                            />
                            <button
                                className="mt-3 sm:mt-0 ml-0 sm:ml-3 bg-blue-950 hover:bg-blue-600 text-white font-medium py-2 px-4 disabled"
                                onClick={handleSearch}
                            >
                                SEARCH
                            </button>
                        </div>
                    </section>

                    <div className="flex flex-col md:flex-row md:justify-between gap-1 mt-2">
                        <div className="w-full md:w-3/12 h-auto mb-2 md:mb-0 text-gray-800">
                            {/* Categories */}
                            <div className="bg-neutral-200 h-auto mt-2 m-1 py-3 px-2">
                                <h6 className="font-bold text-lg">Categories</h6>
                                {/* "All" option */}
                                <div className="flex items-center">
                                    <input
                                        type="radio"
                                        id="allCategories"
                                        name="category"
                                        className="form-radio rounded-none h-3 w-3 text-blue-600"
                                        onChange={() => handleCategoryChange('')}
                                    />
                                    <label htmlFor="allCategories" className="ml-2 text-gray-700 text-sm">All</label>
                                </div>
                                {/* Categories */}
                                {applicantCategories.length === 0 ? (
                                    <div className="flex flex-col items-center justify-center">
                                        <div className="loader"></div>
                                        <p>Loading...</p>
                                    </div>
                                ) : (
                                    applicantCategories.map(category => (
                                        <div key={category.postApplied} className="flex items-center">
                                            <input
                                                type="radio"
                                                id={category.postApplied}
                                                name="category"
                                                className="form-radio rounded-none h-3 w-3 text-blue-600"
                                                onChange={() => handleCategoryChange(category.postApplied)}
                                            />
                                            <label htmlFor={category.postApplied} className="ml-2 text-gray-700 text-sm">
                                                {category.postApplied}
                                            </label>
                                        </div>
                                    ))
                                )}
                            </div>

                            {/* Age range */}
                            <div className="bg-neutral-200 h-auto mt-2 m-1 py-3 px-2">
                                <h6 className="font-bold text-lg">Age</h6>
                                {/* "All" option */}
                                <div className="flex items-center">
                                    <input
                                        type="radio"
                                        id="allAges"
                                        name="age"
                                        className="form-radio rounded-none h-3 w-3 text-blue-600"
                                        onChange={() => handleAgeRangeChange('')}
                                    />
                                    <label htmlFor="allAges" className="ml-2 text-gray-700 text-sm">All</label>
                                </div>
                                {/* Age ranges */}
                                <div>
                                    <div className="flex items-center">
                                        <input
                                            type="radio"
                                            id="age20to30"
                                            name="age"
                                            className="form-radio rounded-none h-3 w-3 text-blue-600"
                                            onChange={() => handleAgeRangeChange( "20-30")}
                                        />
                                        <label htmlFor="age20to30" className="ml-2 text-gray-700 text-sm">20-30</label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            type="radio"
                                            id="age30to40"
                                            name="age"
                                            className="form-radio rounded-none h-3 w-3 text-blue-600"
                                            onChange={() => handleAgeRangeChange( "30-40" )}
                                        />
                                        <label htmlFor="age30to40" className="ml-2 text-gray-700 text-sm">30-40</label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            type="radio"
                                            id="age40to50"
                                            name="age"
                                            className="form-radio rounded-none h-3 w-3 text-blue-600"
                                            onChange={() => handleAgeRangeChange("40-50" )}
                                        />
                                        <label htmlFor="age30to40" className="ml-2 text-gray-700 text-sm">40-50</label>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="w-full md:w-9/12">

                            <div className="h-[52px] m-1 mt-0 ">
                                <div className="flex">
                                    <div className="block w-2/4">
                                        <div className="text-yellow-500 font-medium text-lg mb-2">
                                            <h5>Candidates Found: {totalApplicants < 10 ? `0${totalApplicants}` : totalApplicants}</h5>
                                        </div>
                                        <div className="text-neutral-200 font-normal text-xs uppercase">
                                            <span>Displayed Here: {applicantCount < 10 ? `0${applicantCount}` : applicantCount} of {totalApplicants < 10 ? `0${totalApplicants}` : totalApplicants}</span>
                                        </div>
                                    </div>

                                    <div className="flex w-2/4 justify-end items-center">
                                        <div>

                                            <div className="text-gray-800 mr-3">
                                                <label htmlFor="recordsPerPage" className="mr-2 text-neutral-200">Records per page:</label>
                                                <select
                                                    id="recordsPerPage"
                                                    name="recordsPerPage"
                                                    value={recordsPerPage}
                                                    onChange={handleRecordsPerPageChange}
                                                    className="border border-gray-300 rounded-md px-2 py-1"
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </select>
                                            </div>

                                        </div>
                                        <div>

                                        </div>
                                        <div>
                                            <button
                                                className="bg-gray-500 hover:bg-gray-900 p-1 rounded-full"
                                                onClick={handleReload}>
                                                <ArrowPathIcon className="h-[20px]"/>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/* Second new div */}
                            <div className="m-1 flex flex-col">
                                {applicantData.length === 0 ? (
                                    <div className="flex flex-col items-center justify-center">
                                        <div className="loader"></div>
                                        <p>Loading...</p>
                                    </div>
                                ) : (

                                    applicantData.map(applicant => (
                                        <div key={applicant.ref_no}
                                             className="bg-neutral-200 p-2 shadow-md  flex flex-col md:flex-row md:justify-between mt-1">
                                            <div className="w-full md:w-3/12 h-auto md:mb-0 ">
                                                <img
                                                    src={`${BackendUrl}/${applicant.photo}`}
                                                    alt="Applicant Photo"
                                                    className="border border-gray-800 p-1 max-w-full"
                                                    style={{ height: 'auto' }}
                                                />
                                            </div>

                                            <div className="w-full ml-2 md:w-9/12 h-auto md:mb-0">
                                                {/* Div for Reference */}
                                                <div className="pb">
                                                    <h2 className="text-sm font-semibold text-gray-500">
                                                        REF. NO. #{applicant.ref_no}
                                                    </h2>
                                                </div>

                                                {/* Div for Name */}
                                                <div className="pb">
                                                    <h2 className="text-lg font-bold text-gray-700 uppercase">
                                                        {applicant.fullName}
                                                    </h2>
                                                </div>

                                                {/* Div for Position*/}
                                                <div className="pb-2">
                                                    <h2 className="text-md font-medium text-gray-700 capitalize">
                                                        {applicant.postApplied}
                                                    </h2>
                                                </div>

                                                {/* Div for Experian */}
                                                <div className="md:flex md:items-center md:justify-between pb-2">
                                                    {/* Content related to Experian on the left */}
                                                    <div>
                                                        <h2 className="text-md font-semibold text-gray-800">
                                                            Age: {applicant.Age}
                                                        </h2>
                                                    </div>

                                                    {/* Text in the middle */}
                                                    <div className=" md:block">
                                                        <h2 className="text-md font-semibold text-gray-800">
                                                            Civil Status: {applicant.maritalStatus}
                                                        </h2>
                                                    </div>

                                                    {/* Additional content on the right */}
                                                    <div className=" md:block">
                                                        <h2 className="text-md font-semibold text-gray-800">
                                                            Religion: {applicant.religion}
                                                        </h2>
                                                    </div>

                                                </div>

                                                {/* Div for Civil Status */}
                                                <div className="md:flex md:items-center md:justify-between pb-2">
                                                    {/* Additional content on the right */}
                                                    <div className=" md:block">
                                                        <h2 className="text-md font-semibold text-gray-800 capitalize">
                                                            English: {applicant.english}
                                                        </h2>
                                                    </div>

                                                    {/* Additional content on the right */}
                                                    <div className=" md:block">
                                                        <h2 className="text-md font-semibold text-gray-800 capitalize">
                                                            Arabic: {applicant.arabic}
                                                        </h2>
                                                    </div>
                                                </div>

                                                {/* Div for Nationality */}
                                                <div className="pb-2">
                                                    <h2 className="text-md font-normal text-gray-700 capitalize flex">
                                                        <MapPinIcon className="w-[20px]"/>{applicant.nationality}
                                                    </h2>
                                                </div>

                                                {/* Div for Additional Details */}
                                                <div className="py-1 flex flex-col md:flex-row md:justify-between">
                                                    <button
                                                        onClick={() => handlePDFDownload(applicant.ref_no)}
                                                        className="bg-blue-950 hover:bg-blue-600 text-white px-4 py-2  mb-2 md:mb-0 md:mr-2 flex">
                                                        Download CV <ArrowDownTrayIcon className="w-[20px] ml-3"/>
                                                    </button>

                                                    {/* More Details Button */}
                                                    <button
                                                        onClick={() => moreDetails(applicant.ref_no, applicant)}
                                                        className="bg-green-950 hover:bg-green-600 text-white px-4 py-2 mb-2 md:mb-0 md:mr-2 flex">
                                                        More Details <DocumentTextIcon className="w-[20px] ml-3"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Pagination */}
                    <div className="flex justify-end items-center mt-4 mb-3">
                        <button
                            className="hover:bg-blue-700 backdrop-blur-sm bg-white/30 text-md text-white font-bold py-2 px-2 mr-2 rounded-full"
                            onClick={handlePreviousPage}
                        >
                            <BackwardIcon className={"h-[20px]"}/>
                        </button>
                        <div className="flex items-center mx-2">
                            <span className="mr-1 text-sm font-medium">Page {currentPage} of {totalPages}</span>
                        </div>
                        <button
                            className="hover:bg-blue-700 backdrop-blur-sm bg-white/30 text-md text-white font-bold py-2 px-2 ml-2 rounded-full"
                            onClick={handleNextPage}
                        >
                            <ForwardIcon className={"h-[20px]"}/>
                        </button>
                    </div>


                </div>



            </div>


        </>
    );
};

export default Applicants;
