import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import BackendUrl from "../DBConfig";
import {toast, ToastContainer} from "react-toastify";
import {BackwardIcon, ForwardIcon, HomeIcon} from "@heroicons/react/24/solid";
import Breadcrumb from "../components/breadcrumb";
import {ArrowDownTrayIcon, ArrowPathIcon, DocumentTextIcon, MapPinIcon} from "@heroicons/react/24/outline";
import whatsappBtn from "../images/buttons/WhatsAppButtonGreenLarge.png";

const ApplicantDetails = () => {
    const { applicantId } = useParams();
    const navigate = useNavigate();
    const [Client, setClient] = useState([]);
    const [loading, setLoading] = useState(true);
    const [qualificationLoading, setQualificationLoading] = useState(true);
    const [experienceLoading, setExperienceLoading] = useState(true);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [applicant, setApplicantData]=useState({});
    const [qualification, setQualification]=useState([]);
    const [experience, setExperience]=useState([]);



    const handlePDFDownload = (applicantId) => {
        navigate(`/applicant/cv/${applicantId}`);
    };

    const handleWhatsapp = (applicantId, applicantName, clientName, clientEmail) => {
        const message = `The Applicant with the following details: Ref. No: ${applicantId} and Applicant Name: ${applicantName} is wanted by ${clientName} (${clientEmail})`;
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/768137599?text=${encodedMessage}`;

        window.open(whatsappUrl, '_blank');
    };



    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const storedClient = localStorage.getItem("client");
                setClient(JSON.parse(storedClient));

                if (!Client || !Client.username) {
                    const reStoredClient = localStorage.getItem("client");
                    const parsedClient = JSON.parse(reStoredClient);

                    if (!parsedClient || !parsedClient.username) {
                        navigate('/login');
                        return;
                    }

                    setClient(parsedClient);
                }

                const fetchApplicant = async () => {
                    try {
                        const response = await axios.get(`${BackendUrl}/applicant/${applicantId}`);
                        const applicantData = response.data;

                            setApplicantData(applicantData);
                            setLoading(false);

                    } catch (error){
                        console.error("Error fetching applicant data:", error);

                    }
                };

                const fetchQualification = async () => {
                    try {
                        const response = await axios.get(`${BackendUrl}/applicant/qualification/${applicantId}`);
                        const qualificationData = response.data;

                        setQualification(qualificationData);
                        setQualificationLoading(false);

                    } catch (error){
                        console.error("Error fetching applicant data:", error);

                    }
                };


                const fetchExperience = async () => {
                    try {
                        const response = await axios.get(`${BackendUrl}/applicant/prev-employment/${applicantId}`);
                        const experienceData = response.data;

                        setExperience(experienceData);
                        setExperienceLoading(false);

                    } catch (error){
                        console.error("Error fetching applicant data:", error);

                    }
                };

                await fetchApplicant();
                await fetchQualification();
                await fetchExperience();

            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Error fetching data. Please try again later.");
                // navigate("/login");
            }
        };

        fetchData();
    }, [applicantId]);


    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: "/" },
        { label: "Applicants", url: "/applicants" },
    ];


    return (
        <>
            <head>
                <title>{applicant.fullName} | Applicant Details</title>
            </head>
            <ToastContainer
                position="bottom-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="flex flex-col justify-start items-center">
                <div className="w-9/12 overscroll-y-auto">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative">
                                {!Client || Object.keys(Client).length === 0 ? (
                                    <div className="absolute right-0">Welcome, Loading...</div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{Client.username || ''} </strong> | {formattedTime}
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>

                    <section className="px-8 w-full h-auto sm:top-[450px] max-w-7xl mt-3 mb-4 flex justify-center items-center">
                        <div className="flex justify-center text-center items-center">
                            <h1 className="font-bold text-xl">Applicant Overview</h1>
                        </div>
                    </section>



                    <div className="flex flex-col md:flex-row md:justify-between gap-1 mt-2">


                        <div className="w-full md:w-9/12 h-auto ">

                            { loading ?
                                (
                                    <div className="flex flex-col items-center justify-center">
                                        <div className="loader"></div>
                                        <p>Loading...</p>
                                    </div>

                                ):(


                            <div className="h-auto m-1 mt-0 flex flex-col">
                                <div className="bg-neutral-200 p-2  shadow-md  flex flex-col md:flex-row md:justify-between ">
                                    <div className="w-full md:w-3/12 h-auto md:mb-0 ">
                                        <img
                                            src={`${BackendUrl}/${applicant.photo}`}
                                            alt="Company Logo"
                                            className="border border-gray-800 max-w-full"
                                            style={{ height: 'auto' }}
                                        />

                                    </div>

                                    <div className="w-full px-3 md:w-9/12 h-auto bg-neutral-200md:mb-0">
                                        {/* Div for Name */}
                                        {/* Div for Reference */}
                                        <div className="pb">
                                            <h2 className="text-sm font-semibold text-gray-500">
                                                REF. NO. #{applicant.ref_no}
                                            </h2>
                                        </div>

                                        {/* Div for Name */}
                                        <div className="pb">
                                            <h2 className="text-lg font-bold text-gray-700 uppercase">
                                                {applicant.fullName}
                                            </h2>
                                        </div>

                                        {/* Div for Position*/}
                                        <div className="pb-2">
                                            <h2 className="text-md font-medium text-gray-700 capitalize">
                                                {applicant.postApplied}
                                            </h2>
                                        </div>

                                        {/* Div for Experian */}
                                        <div className="md:flex md:items-center md:justify-between pb-2">
                                            {/* Content related to Experian on the left */}
                                            <div>
                                                <h2 className="text-md font-semibold text-gray-800">
                                                    Age: {applicant.Age}
                                                </h2>
                                            </div>

                                            {/* Text in the middle */}
                                            <div className=" md:block">
                                                <h2 className="text-md font-semibold text-gray-800">
                                                    Civil Status: {applicant.maritalStatus}
                                                </h2>
                                            </div>

                                            {/* Additional content on the right */}
                                            <div className=" md:block">
                                                <h2 className="text-md font-semibold text-gray-800">
                                                    Religion: {applicant.religion}
                                                </h2>
                                            </div>

                                        </div>

                                        {/* Div for Civil Status */}
                                        <div className="md:flex md:items-center md:justify-between pb-2">
                                            {/* Additional content on the right */}
                                            <div className=" md:block">
                                                <h2 className="text-md font-semibold text-gray-800 capitalize">
                                                    English: {applicant.english}
                                                </h2>
                                            </div>

                                            {/* Additional content on the right */}
                                            <div className=" md:block">
                                                <h2 className="text-md font-semibold text-gray-800 capitalize">
                                                    Arabic: {applicant.arabic}
                                                </h2>
                                            </div>
                                        </div>

                                        {/* Div for Nationality */}
                                        <div className="pb-2">
                                            <h2 className="text-md font-normal text-gray-700 capitalize flex">
                                                <MapPinIcon className="w-[20px]"/>{applicant.nationality}
                                            </h2>
                                        </div>


                                    </div>
                                </div>
                            </div>
            )}



                            {/* 2 new div */}
                            <div className="h-auto m-1 flex flex-col">
                                <div className="bg-white p-2 shadow-md   mt-2">
                                    <h2 className="text-lg font-semibold text-gray-800">Education Qualification</h2>

                                    {qualificationLoading ? (
                                        <div className="flex flex-col items-center justify-center">
                                            <div className="loader"></div>
                                            <p>Loading...</p>
                                        </div>

                                        ):(
                                            <div >

                                                {qualification.map(qualification => (
                                                    <div key={qualification.id}>
                                                        <p className="text-md font-semibold text-red-800">{qualification.qualification}</p>

                                                    </div>
                                                ))

                                                }
                                            </div>
                                        )}
                                </div>
                            </div>

                            {/* 3 new div */}
                            <div className="h-auto m-1 flex flex-col">
                                <div className="bg-white p-2 shadow-md   mt-2">

                                    <h2 className="text-lg font-semibold text-gray-800">Experience</h2>

                                    {experienceLoading ? (
                                        <div className="flex flex-col items-center justify-center">
                                            <div className="loader"></div>
                                            <p>Loading...</p>
                                        </div>

                                    ):(
                                        <div>
                                            {experience.map(experience => (
                                                <div key={experience.id}>
                                                    <span className="text-md font-semibold text-red-800">{experience.country} - </span>
                                                    <span className="text-md font-semibold text-red-800">{experience.duration} Years</span>

                                                </div>
                                            ))

                                            }
                                        </div>
                                    )}
                                </div>
                            </div>



                        </div>

                        <div className="w-full md:w-3/12 h-auto mb-2 md:mb-0 text-gray-800">
                            {/* Categories */}
                            <div className="bg-neutral-200 h-auto mt-0 m-1 py-3 px-2">
                                <h6 className="font-bold text-lg">Candidate Overview</h6>
                                <form>
                                    <div className="mt-3">
                                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
                                        <input type="text" id="firstName" name="firstName" className="mt-1 p-2 border border-gray-300 rounded-md w-full" />
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
                                        <input type="text" id="lastName" name="lastName" className="mt-1 p-2 border border-gray-300 rounded-md w-full" />
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
                                        <input type="email" id="email" name="email" className="mt-1 p-2 border border-gray-300 rounded-md w-full" />
                                    </div>
                                    <button type="submit" className="mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
                                </form>
                            </div>



                            <div className="h-auto mt-2 m-1">
                                <button
                                    onClick={() => handlePDFDownload(applicant.ref_no)}
                                    className="font-bold text-lg w-full h-full bg-yellow-700 hover:bg-yellow-400 py-3 text-neutral-200 flex justify-center items-center mb-2">
                                    View & Download CV
                                    <ArrowDownTrayIcon className="w-[30px] ml-2"/>
                                </button>

                                <button
                                    onClick={() => handleWhatsapp(applicant.ref_no, applicant.fullName, Client.username, Client.email)}
                                    className="font-bold text-lg w-full h-full text-neutral-200 flex justify-center items-center ">
                                    <img
                                        src={whatsappBtn}
                                        className="hover:scale-105"
                                        alt={"Whatsapp Button"}
                                    />
                                </button>


                            </div>

                        </div>

                    </div>



                </div>



            </div>


        </>
    );
};

export default ApplicantDetails;