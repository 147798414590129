import React from 'react';
import Header from "../components/header"; // Corrected import statement
import {Route, Routes} from "react-router-dom";
import Home from "../pages/Home";
import Applicants from "../pages/Applicants";
import Footer from "../components/footer";
import TimeBar from "../components/TimeBar";
import Pdf from "../components/PDF-Popup";
import ApplicantDetails from "../pages/Applicant-Details";


const Dashboard = () => {

    return (
        <div className="h-screen max-h-screen flex flex-col">

                <Header/>
            <TimeBar />

            <div className="flex-grow overflow-y-auto">


                <div className="container p-0 text-light">
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/home" element={<Home />} />
                        <Route exact path="/applicants" element={<Applicants />} />
                        <Route exact path="/applicant/info/:applicantId" element={<ApplicantDetails/>} />


                        <Route exact path="/applicant/cv/:applicantId" element={<Pdf />} />


                    </Routes>
                </div>
            </div>
            <Footer classname="fixed bottom-0"/>

        </div>
    );
};

export default Dashboard;