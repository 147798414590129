import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import image1 from '../images/logo-slider/1.webp';
import image2 from '../images/logo-slider/2.webp';
import image3 from '../images/logo-slider/3.webp';
import image4 from '../images/logo-slider/4.webp';
import image5 from '../images/logo-slider/5.webp';

const ImageSlider = () => {
    const images = [
        { src: image1, alt: 'Image 1' },
        { src: image2, alt: 'Image 2' },
        { src: image3, alt: 'Image 3' },
        { src: image4, alt: 'Image 4' },
        { src: image5, alt: 'Image 5' },
    ];

    const groupedImages = [];
    for (let i = 0; i < images.length; i += 3) {
        groupedImages.push(images.slice(i, i + 3));
    }


    return (
        <div >
            <Carousel showArrows={true} emulateTouch={true} showThumbs={false}>
                {groupedImages.map((group, index) => (
                    <div key={index}>
                        <div className="image-group">
                            {group.map((image, i) => (
                                <img key={i} src={image.src} alt={image.alt} className="h-1/12 w-auto"/>
                            ))}
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default ImageSlider;
