import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Dashboard from "./view/Dashboard";
import Login from "./pages/Login";
import Registration from "./pages/Registration";

function App() {
  return (
      <div className="App bg-blue-950" style={{height:'100vh'}}>
        <Router>
          <Routes>


            <Route exact path="/login" element={<Login />} />
            <Route exact path="/registration" element={<Registration />} />
              <Route exact path="/*" element={<Dashboard />} />

          </Routes>

        </Router>
      </div>
  );
}

export default App;