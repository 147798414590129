import React, { useEffect, useState } from 'react';
import '../css/loader.css';
import Breadcrumb from '../components/breadcrumb';
import { HomeIcon } from "@heroicons/react/24/solid";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import ImageSlider from "./Image-Slider";
import axios from "axios";
import BackendUrl from "../DBConfig";
import {toast} from "react-toastify";

const Home = () => {
    const navigate = useNavigate();
    const [Client, setClient] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [applicantNationality, setApplicantNationality] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [nationality, setNationality] = useState('');
    const [ageRange, setAgeRange] = useState('');

    const images = [
        "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
        "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
        "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    ];

    const handleSearch = () => {
        const baseUrl = '/applicants';
        const queryParams = [];

        // Add keyword parameter
        queryParams.push(`keyword=${encodeURIComponent(keyword.trim())}`);

        // Add nationality parameter
        queryParams.push(`nationality=${encodeURIComponent(nationality.trim())}`);

        // Add age parameter
        queryParams.push(`age=${encodeURIComponent(ageRange.trim())}`);

        // Combine base URL and query parameters
        const url = `${baseUrl}?${queryParams.join('&')}`;

        // Redirect or fetch data using the constructed URL
        navigate(url); // For demonstration, you can redirect or fetch data using this URL


    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedClient = localStorage.getItem("client");
                if (storedClient) {
                    setClient(JSON.parse(storedClient));
                } else {
                    navigate('/login');
                }
                try {
                    const nationalityResponse = await axios.get(`${BackendUrl}/applicant/nationalities/`);
                    const allNationalities = nationalityResponse.data;

                    setApplicantNationality(allNationalities);
                } catch (error) {
                    console.error('Error fetching applicant nationality:', error);
                }

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setTimeout(() => {
                    fetchData();
                }, 5000);
            }
        };

        fetchData();
    }, [navigate]);


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Dashboard', url: '/' },
    ];

    if(loading){
        return (
            <div>
                <div className="flex flex-col items-center justify-center">
                    <div className="loader"></div>
                    <p>Loading...</p>
                </div>
            </div>
        )
    }




    return (
        <div className="">
            <div className="flex flex-col justify-start items-center">
                <div className="w-9/12 overscroll-y-auto text-neutral-200">
                    <div className="col-span-12 mt-0">
                        <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">
                            <div className="mt-3">
                                <Breadcrumb items={breadcrumbItems} />
                            </div>
                            <div className="mt-3 relative">
                                {Client.length === 0 ? (
                                    <div className="absolute right-0">
                                        Welcome, Loading...
                                    </div>
                                ) : (
                                    <div className="absolute right-0 capitalize">
                                        Welcome, <strong>{Client.username} </strong> | {formattedTime}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>




                    <div className="col-span-12 mt-0 ">
                        <div className="flex flex-col h-[60vh] max-h-[60vh] items-center justify-center">

                            <div>
                            {/*    Carousel*/}
                                <ImageSlider/>

                            </div>

                            <div className="pb-4 w-full max-w-7xl mt-3 rounded-lg">

                                <div className="flex items-center  justify-center sm:flex-row px-2">

                                    <input
                                        type="text"
                                        name="keyword"
                                        id="keyword"
                                        className="block mt-3 w-full sm:w-1/3 border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-stone-100"
                                        placeholder="Enter search keyword"
                                        onChange={(e) => setKeyword(e.target.value)}
                                    />
                                    <select
                                        name="nationality"
                                        id="nationality"
                                        className="mt-3 sm:mt-0 ml-0 sm:ml-3 block w-full sm:w-1/4 border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-stone-100"
                                        placeholder="Select Nationality"
                                        onChange={(e) => setNationality(e.target.value)}
                                    >

                                        <option value={""}>Select Nationality</option>
                                        {applicantNationality.map((nationality, index) => (
                                            <option
                                                key={index}>{nationality.nationality}</option>
                                        ))}

                                    </select>
                                    <select
                                        name="ageRange"
                                        id="ageRange"
                                        className="mt-3 sm:mt-0 ml-0 sm:ml-3 block w-full sm:w-1/4 border-0 py-2 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-stone-100"
                                        placeholder="Select Age Range"
                                        onChange={(e) => setAgeRange(e.target.value)}
                                    >
                                        <option value={""}>Select Age Range</option>
                                        <option value={"20-30"}>20-30</option>
                                        <option value={"30-40"}>30-40</option>
                                        <option value={"40-50"}>40-50</option>

                                    </select>
                                    <button
                                        className="mt-3 sm:mt-0 ml-0 sm:ml-3 bg-blue-700 hover:bg-blue-600 text-white font-medium py-2 px-4 disabled"
                                        onClick={handleSearch}

                                    >
                                        SEARCH
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default Home;
